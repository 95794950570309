export default () => {
	return {
		listData: [], //企业、项目列表数据
		searchContent: '', //全局查询框输入内容
		searchCondition: '', //列表页面模糊查询框输入内容
		enterprise_info: {
			name: '', //企业名称
			person: '', // 负责人
			location: '', // 坐标
			address: '', // 地址
			id: null,
		}, //企业信息
		enterprise_project: [],// 当前企业下项目信息
		project_info: {
			name: '', //项目名称
			person: '', // 负责人
			location: '', // 坐标
			address: '', // 地址
			content: '',//项目描述
			bcname: '',//爆破单位
			companyId: null,//所属企业
			id: null
		}, //项目信息
		project_parent_info: {},// 选择的项目的公司信息
		scenario: [{
			value: '1',
			label: '桥梁'
		}, {
			value: '2',
			label: '边坡'
		}, {
			value: '3',
			label: '隧道'
		}, {
			value: '4',
			label: '场馆'
		}, {
			value: '5',
			label: '其他'
		}], //项目场景选项
		enterprise_backupInfo: {}, //企业备份信息
		project_backupInfo: {}, //项目备份信息
		addEnterprise: {
			name: '', //公司名称
			location: '', // 坐标
			address: '', // 地址
			id: null,
		}, // 新增企业
		addProject: {
			name: '', //项目名称
			companyId: '', //项目所属公司
			location: '', // 坐标
			address: '', // 地址
			content:'',// 描述
			bcname: '',//爆破单位
			id: null
		}, // 新增项目
		describe: {
			id: null,
			content: null,
		},// 编辑项目描述内容
		allEnterprise: [], //可选择的公司列表
		status: '', //显示企业信息/项目信息
		editMode: false, //是否处于编辑模式
		revised: false, // 是否修改过企业或者项目
		activeIndex: '1', //列表菜单选择
		addPointParameter: {
			limit: 1, //最大文件个数
			name: 'component-addPoint' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		}, //测点布设图图片上传
		settingMap: false, //是否编辑测点布设图
		parameterPoint: {
			limit: 1, //最大文件个数
			name: 'component-point' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		}, //测点布设图图片上传
		map: null, //地图对象
		localSearch: null, //地址模糊查询对象
		selectPoint: '', // 地图选点的经纬度
		inputName: '', // 地图选点输入的地址名称
		butName: "", //点击地址选点时的按钮功能名称。例如'新增企业'
		//权限数组中id对应的功能 name:创建企业\创建项目\修改企业\删除企业\修改项目\删除项目\创建设备\修改设备\删除设备\管理测点\报告图\测点布设图
		authorityList: [], //权限数组 id:274\275\276\277\278\279\280\271\282\283\306\308   
		equipmentTotal: 0, //设备数据总条数
		currentPage: 1, //当前页数
		pagenumber: 1, //跳转页码
		pagesize: 30, //每页条数
		total: 0, //总条数
		ops: {
			bar: {
				onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
				keepShow: true, //滚动条一直显示
			}
		}, //滚动条设置
	}
}
