<template>
	<!-- 设备列表 -->
	<div class="sensor-box">
		<div class="table-box">
			<el-table :data="tableData" style="width: 100%" height="100%">
				<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
				<el-table-column v-if="text == '企业'" label="项目名" prop="name" align="center"></el-table-column>
				<el-table-column label="设备编号" prop="imei" align="center"></el-table-column>
				<el-table-column label="数据条数" prop="number" align="center"></el-table-column>
				<el-table-column label="时间" prop="cdate" align="center"></el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-but">
							<el-button type="primary" plain size="mini" icon="el-icon-edit" @click="open('add-equipment', true, scope.row)"></el-button>
							<el-button type="danger" plain size="mini" icon="el-icon-delete" @click="deleteSensor(scope.row)"></el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 设备添加/编辑 -->
		<div class="add-equipment equipment-style Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editEquipment ? '编辑设备' : '添加设备' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-equipment')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							设备编号
						</span>
						<el-input v-model="addEquipment.imei" placeholder="请输入内容" maxlength="15" show-word-limit></el-input>
					</div>
					<!-- v-show="judgment('authorityList', 290, this)" -->
					<div class="cell" v-show="editEquipment">
						<span class="title">
							<i>*</i>
							所属企业
						</span>
						<el-select v-model="addEquipment.companyId" filterable placeholder="请选择企业" @change="enterpriseChange">
							<el-option v-for="item in allEnterprise" :key="item.id" :label="item.text" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell" v-show="editEquipment">
						<span class="title">所属项目</span>
						<el-select v-model="addEquipment.projectId" filterable :disabled="projectOption.length == 0" placeholder="请选择项目">
							<el-option v-for="item in projectOption" :key="item.id" :label="item.text" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="button-box">
						<el-button type="primary" v-show="!editEquipment" size="mini" @click="addEquipments">添加</el-button>
						<el-button type="primary" v-show="editEquipment" size="mini" @click="equipmentEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		// 权限列表
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		// 选择企业项目信息
		enterprise_project: {
			type: Array,
			default: () => {
				return [];
			}
		},
		// 选择的是企业或者项目
		text: {
			type: String,
			default: '企业',
		}
	},
	data() {
		return {
			tableData: [],
			editEquipment: false, //是否编辑设备
			allEnterprise: [], //设备选择的项目列表
			projectOption: [], //设备选择的项目列表
			addEquipment: {
				imei: '', // 设备编号
				projectId: '', // 项目id
				id: '',
				companyId: '' //企业id
			}, // 添加设备/设备编辑
			requestInfo: {
				page: 1,
				size: 30,
				name: ''
			},
			refreshTotal: false, //是否刷新父组件的分页总数
			total: 0 //数据总数
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 获取列表数据
		getListData(value = {}, total = false) {
			for (let k in this.requestInfo) {
				if (value.hasOwnProperty(k)) {
					this.requestInfo[k] = value[k];
				}
			}
			this.refreshTotal = total;
			var data = {
				name: this.requestInfo.name,
				page: this.requestInfo.page,
				size: this.requestInfo.size,
				projectId: this.$parent.project_info.id,
				companyId: this.$parent.enterprise_info.id
			};
			// console.log(this.enterprise_project)
			this.axios.post('web2/bxtk/mgde/odnr/glist', data).then(res => {
				this.tableData = [];
				if (res.status) {
					res.data.list.forEach((item)=>{
						let obj = {
							name: ''
						};
						try{
							this.enterprise_project.forEach((i)=>{
								if(item.pjid == i.id){
									obj.name = i.name;
									throw ''
								}
							})
						}catch(e){}
						this.tableData.push({
							...item,
							...obj
						})
					})
					// this.tableData = res.data.list;
					// // console.log(this.tableData)
					this.total = res.data.total;
					this.$parent.equipmentTotal = this.total;
					let len = res.data.list.length;
					if(data.page != 1 && len == 0 && res.data.total != 0){
						this.requestInfo.page = data.page - 1;
						this.getListData(value, total);
					}
					if (this.refreshTotal) {
						this.$parent.total = this.total;
					}
				}
			});
		},
		// 添加、修改设备请求的企业项目接口
		getCompany() {
			this.allEnterprise = [];
			this.axios.get('web2/bxtk/mgde/mda/gcpjt').then(res => {
				this.allEnterprise = [];
				if (res.status) {
					this.allEnterprise = res.data;
					if (this.editEquipment) {
						this.enterpriseChange();
					} else if (res.data.length == 1) {
						this.addEquipment.companyId = res.data[0].id;
						this.enterpriseChange();
					}
				}
			});
		},
		// 添加、编辑设备企业选择
		enterpriseChange() {
			let len = this.allEnterprise.length;
			for (let i = 0; i < len; i++) {
				let item = this.allEnterprise[i];
				if (this.addEquipment.companyId == item.id) {
					this.projectOption = item.children;
					if (item.children.length == 1) {
						this.addEquipment.projectId = item.children[0].id;
					}
					break;
				}
			}
		},
		// 添加设备
		addEquipments() {
			var data = {};
			if(!this.detectionIMEI()){
				return
			}
			if (this.addEquipment.imei == '') {
				this.$message({
					showClose: true,
					message: '请填写必填项',
					type: 'warning'
				});
				return;
			}
			for (let k in this.addEquipment) {
				if (k != 'id') {
					data[k] = this.addEquipment[k];
				}
			}

			this.axios.post('web2/bxtk/mgde/mda/add', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备新增成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('add-equipment');
				}
			});
		},
		// 编辑设备
		equipmentEdit() {
			let data = {};
			if(!this.detectionIMEI()){
				return
			}
			if (this.addEquipment['companyId'] + '' == '') {
				this.$message({
					showClose: true,
					message: '请选择公司！',
					type: 'warning'
				});
				return;
			}
			for (let k in this.addEquipment) {
				data[k] = this.addEquipment[k];
			}
			this.axios.post('web2/bxtk/mgde/mda/md', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备信息修改成功',
						type: 'success'
					});
					this.$parent.getAllList();
					this.close('add-equipment');
				}
			});
		},
		// 删除设备
		deleteSensor(row) {
			this.$confirm('此操作将删除设备：<strong>' + row.imei + '</strong>，是否继续！', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/bxtk/mgde/mda/dltd', { id: row.id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								message: `传感器：${row.imei} 删除成功！`,
								type: 'success'
							});
							this.$parent.getAllList();
						}
					});
				})
				.catch(() => {});
		},
		// 传感器类型数据回显格式化
		typeFormatter(val) {
			let txt = '';
			try {
				this.typeOption.forEach(item => {
					if (item.id == val) {
						txt = item.name;
						throw Error();
					}
				});
			} catch (e) {
				//TODO handle the exception
				return txt;
			}
			return txt;
		},
		// 检测设备编号是否合规
		detectionIMEI(){
			if (this.addEquipment.imei == '') {
				this.$message({
					showClose: true,
					message: '设备编号不能为空',
					type: 'warning'
				});
				return false;
			}
			let text = this.addEquipment.imei;
			let flag = /^[A-Za-z0-9]{1,15}$/;
			if (escape(text).indexOf("%u") < 0) {
				if (!flag.test(text)) {
					this.$message({
						showClose: true,
						message: '设备编号不能含有英文符号或特殊符号！',
						type: 'warning'
					});
					return false;
				}
			} else {
				this.$message({
					showClose: true,
					message: '设备编号不能含有中文或中文符号！',
					type: 'warning'
				});
				return false;
			}
			return true;
		},
		// 打开弹窗
		open(val, status, data = {}) {
			$('.' + val).show();

			if (val == 'add-equipment') {
				// 打开采集器新增设备弹窗
				this.editEquipment = status;
				if (status) {
					for (let k in this.addEquipment) {
						if (data.hasOwnProperty(k)) {
							this.addEquipment[k] = data[k];
						}
					}
					this.addEquipment.companyId = data.cid;
					this.addEquipment.projectId = data.pjid === 0 ? '' : data.pjid;
					this.getCompany();
				} else {
					// // console.log(this.$parent.enterprise_info);
					// // console.log(this.$parent.project_info);
					this.addEquipment.companyId = this.$parent.enterprise_info.id ? this.$parent.enterprise_info.id : this.$parent.project_info.companyId;
					this.addEquipment.projectId = this.$parent.project_info.id;
				}
			}
		},
		// 关闭弹窗
		close(val) {
			if (val != 'binding') {
				$('.' + val).hide();
			}
			if (val == 'add-equipment') {
				//恢复新增采集器设备数据
				for (let key in this.addEquipment) {
					this.addEquipment[key] = '';
				}
			} else if (val == 'binding') {
				this.clearData(this.bindingInfo);
			}
		},
		// 清空数据赋值信息
		clearData(data) {
			// // console.log(data)
			for (let key in data) {
				if (key != 'id') {
					data[key] = '';
				} else {
					data[key] = null;
				}
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.sensor-box {
	width: 100%;
	height: 100%;

	.green {
		color: #00ff7f;
	}
	.grey {
		color: #c0c4cc;
	}

	.table-box {
		width: 100%;
		height: 100%;

		::v-deep {
			.el-table__header-wrapper {
				tr {
					background-color: #ebeef5;
				}

				.el-table__cell {
					background: none;
					font-size: 15px;
					font-weight: 600;
					color: #4c4e51;
					letter-spacing: 1px;
				}
			}

			.el-table__body-wrapper {
				.el-table__row:nth-child(even) {
					background-color: #f0f2fc;
				}

				.el-table__row:nth-child(odd) {
					background-color: #f2fafa;
				}

				.el-table__cell {
					font-size: 14px;
					color: #606266;
					padding: 10px 0;
					border: none;
				}

				.table-txt-but {
					.el-button {
						padding: 6px 6px;
					}

					.el-button + .el-button {
						margin-left: 0;
					}
				}

				.table-but {
					.el-button {
						padding: 4px 5px;
						font-size: 16px;
					}
				}
			}
		}
	}

	// 新增/编辑弹窗输入框样式
	.Mask-box {
		position: fixed;

		.main-box {
			.pop-ups-content {
				padding: 10px;
				background-color: #ffffff;

				.cell {
					width: 100%;
					display: flex;
					// flex-direction: column;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 12px;

					::v-deep {
						.el-input__inner {
							color: #000000;
							font-size: 16px;
						}
					}

					.title {
						font-size: 15px;
						color: #606266;
						// margin-bottom: 5px;

						i {
							color: #f20000;
							font-size: 12px;
						}
					}

					.cell-content {
						width: calc(100% - 120px);
						font-size: 16px;
					}
				}

				.tips {
					word-break: break-all;
					font-size: 12px;
					margin-bottom: 12px;

					i {
						color: #f20000;
					}
				}

				.button-box {
					text-align: center;
					padding: 12px 12px 0 12px;
				}
			}
		}
	}

	// 设备添加、编辑弹窗样式
	.equipment-style {
		z-index: 1000;
		display: none;

		.main-box {
			width: 380px;
			height: 311px;

			.pop-ups-content {
				.cell {
					::v-deep {
						.el-input {
							width: calc(100% - 120px);
						}

						.el-select {
							width: calc(100% - 120px);

							.el-input {
								width: 100%;
							}

							.el-input__icon {
								line-height: initial;
							}
						}

						.el-switch__label * {
							font-size: 13px;
							color: #dcdfe6;
						}

						.is-active span {
							color: #409eff !important;
						}
					}

					.title {
						width: 110px;
						display: inline-block;
					}
				}
			}
		}
	}

	// 绑定传感器弹窗
	::v-deep .bindingSensor {
		.el-dialog__body {
			padding: 15px 15px 20px 15px;
		}

		.mian-box {
			width: 100%;

			.el-form {
				.el-form-item {
					margin-bottom: 10px;
				}
			}
		}
	}
}
</style>
