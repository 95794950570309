import data from "./equipmentManagementData.js";
import imgSign from "./child/imgSign.vue";
import uploadImg from "../../../components/uploadImg.vue";

// 设备列表
import equipment from "./child/equipment.vue";

export default {
	name: "equipmentManagement",
	data,
	created() {
		this.getAuthorityList();
		this.getList();
	},
	mounted() {},
	computed: {},
	methods: {
		// 接口开头 
		// 获取权限列表 cmn
		getAuthorityList() {
			this.axios.get('web2/bxtk/mgde/odnr/gcmn').then((res) => {
				this.authorityList = [];
				if (res.status) {
					this.authorityList = res.data;
				}
			})
		},
		// 获取公司项目列表
		getList(data = {}) {
			this.axios.post('web2/bxtk/mgde/odnr/gcpt', data).then((res) => {
				this.listData = [];
				if (res.status) {
					this.listData = res.data;
					if (res.data.length != 0 && !this.revised) {
						$(() => {
							$('.text-box').eq(0).bind("selectText", (e, data) => {
								this.selectText(e, data);
							})
							$('.text-box').eq(0).trigger("selectText", [res.data[0]])
						})
					}
				}
			})
		},
		// 添加公司
		addCompany() {
			this.axios.post('web2/dfmt/mand/dmc/adc', this.addEnterprise).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '企业新增成功',
						type: 'success'
					});
					this.getList();
					this.close('add-enterprise');
				}
			})
		},
		// 添加项目 
		addProjectf() {
			this.axios.post('web2/dfmt/mand/dmd/adpj', this.addProject).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '项目新增成功',
						type: 'success'
					});
					this.getList();
					this.close('add-project');
				}
			})
		},
		// 添加项目获取公司列表
		getCompany() {
			var data = {
				params: {
					loading: false
				}
			};
			this.axios.get('web2/dfmt/mand/dmd/gcin', data).then((res) => {
				this.allEnterprise = [];
				if (res.status) {
					this.allEnterprise = res.data;
					if (res.data.length == 1) {
						this.addProject.companyId = res.data[0].id;
					}
				}
			})
		},
		// 项目删除
		projectDelete(id, name) {
			this.$confirm('此操作将删除该<strong>' + name + '</strong>下所有数据，是否继续！', '严重警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					customClass: 'icon-big',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					type: 'error',
					center: true,
					dangerouslyUseHTMLString: true
				})
				.then(() => {
					// dpj
					this.axios.post('web2/dfmt/mand/dmh/dtpj', {
						id
					}).then((res) => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
						}

					})

				})
				.catch(() => {});
		},
		// 企业删除
		enterpriseDelete(id, name) {
			this.$confirm('此操作将删除该<strong>' + name + '</strong>下所有数据，是否继续！', '严重警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					customClass: 'icon-big',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					center: true,
					dangerouslyUseHTMLString: true
				})
				.then(() => {
					// dc
					this.axios.post('web2/dfmt/mand/dmg/dcm', {
						id
					}).then((res) => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
						}
					})
				})
				.catch(() => {});
		},
		// 修改企业 mc
		modifyEnterprise() {
			var data = {};
			for (let key in this.addEnterprise) {
				if (key != 'person') {
					data[key] = this.addEnterprise[key];
				}
			}
			this.axios.post('web2/dfmt/mand/dme/mcp', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '企业信息修改成功',
						type: 'success'
					});
					this.revised = true;
					this.close('add-enterprise');
					for (let key in data) {
						this.enterprise_info[key] = data[key];
					}
					this.getList();
				}
			})
		},
		// 修改项目 mpj
		modifyProject() {
			var data = {};
			for (let key in this.project_info) {
				if (key != 'person' && key != 'content') {
					data[key] = this.addProject[key];
				}
			}
			this.axios.post('web2/dfmt/mand/dmf/mpj', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '项目信息修改成功',
						type: 'success'
					});
					for (let k in this.project_info) {
						this.project_info[k] = this.addProject[k];
					}
					this.revised = true;
					this.close('add-project')
					this.getList();
				}
			})
		},
		// 编辑项目描述
		editDescription() {
			// var data = {
			// 	id: this.describe.id,
			// 	content: this.describe.content
			// };
			this.axios.post('web2/dfmt/mand/dmf/mpdes', this.describe).then((res) => {
				var status = false;
				if (res.status) {
					// this.$message({
					// 	showClose: true,
					// 	type: 'success',
					// 	message: '编辑成功!'
					// });
					this.project_info.content = this.describe.content;
					for (let key in this.describe) {
						this.describe[key] = null;
					}
					status = true;
				}
				if (this.project_info.pn1 != '') {
					if (status) {
						this.close('add-point');
					}
				} else {
					this.uploadProjectImg(status)
				}
			})
		},
		// 结束项目
		endProject(e) {
			var obj = $(e.currentTarget);
			this.$confirm('请确认<strong>' + this.project_info.name + '</strong>监测工作是否已完成，结束后无法再启动, 是否结束?', '提示', {
				confirmButtonText: '结束',
				cancelButtonText: '取消',
				type: 'warning',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				dangerouslyUseHTMLString: true
			}).then(() => {
				var data = {
					id: this.project_info.id
				};
				// // console.log(data)
				this.axios.post('web2/dfmt/mand/dmn/fps', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '结束成功!'
						});
					}
				})

			}).catch(() => {});
		},
		// 企业/项目修改取消
		cancelModify(val) {
			if (val == "企业") {
				for (let key in this.enterprise_backupInfo) {
					this.enterprise_info[key] = this.enterprise_backupInfo[key];
				}
			} else if (val == "项目") {
				for (let key in this.project_backupInfo) {
					this.project_info[key] = this.project_backupInfo[key];
				}
			}
			this.editMode = false;
		},
		// 设备校准证书编号填写自动填写有效期
		automaticallyFill() {
			var data = this.certificate.serialNumber;
			if (data) {
				this.certificate.years = Number(data.slice(0, 4)) + 1;
				this.certificate.month = data.slice(4, 6);
			}
		},
		// 上传项目图片（测点布设图） id（项目） file
		uploadProjectImg(status) {
			var file = this.$refs.pointImg.getFile();
			if (file.length == 0) {
				// this.$message({
				// 	showClose: true,
				// 	message: '请先选择图片',
				// 	type: 'warning'
				// });
				if (status) {
					this.close('add-point');
				}
				return;
			}
			var data = new FormData();
			data.append("id", this.project_info.id);
			data.append('file', file[0].raw);
			// // console.log(file)
			this.axios.post('web2/dfmt/mand/dmv/uplop', data).then((res) => {
				if (res.status) {
					// this.$message({
					// 	showClose: true,
					// 	message: '图片上传成功',
					// 	type: 'success'
					// });
					this.project_info.pn1 = this.constData.imgUrl + "res/static/ppname/1" + res.data;
					this.project_backupInfo.pn1 = this.constData.imgUrl + "res/static/ppname/1" + res.data;
					this.$forceUpdate();
					if (status) {
						this.close('add-point');
					}
					// 刷新测点布设图组件数据
					setTimeout(() => {
						this.$refs.imgSign.setUrl();
					}, 100)
					this.$refs.pointImg.clearFile();
				}
			})
		},
		// 删除测点布设图 
		deleteProjectImg() {
			this.$confirm('此操作将永久删除测点布设图, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.axios.post('web2/dfmt/mand/dmv/dlpp', {
					id: this.project_info.id
				}).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success'
						});
						this.project_info.pn1 = '';
						this.$forceUpdate();
					}
				});
			}).catch(() => {});
		},
		// 项目设置（测点布设图和项目描述）
		projectSettings() {
			this.editDescription();
		},
		// 企业项目搜索
		search() {
			var data = {
				company: this.searchContent
			};
			this.getList(data)
		},
		// 请求设备列表数据 refreshTotal是否刷新分页
		getAllList(pageData = {}, refreshTotal = {
			equipment: true,
		}) {
			if (!refreshTotal.hasOwnProperty('collector')) {
				if (this.activeIndex == '1') {
					refreshTotal.equipment = true;
				} else {
					refreshTotal.equipment = false;
				}
			}
			this.$refs.equipment.getListData(pageData, refreshTotal.equipment);
		},
		// 信息编辑
		infoEdit(val = "") {
			this.editMode = true;
			if (val == '企业') {
				for (let key in this.addEnterprise) {
					this.addEnterprise[key] = this.enterprise_info[key];
				}
				this.open('add-enterprise');
			} else {
				for (let key in this.addProject) {
					this.addProject[key] = this.project_info[key];
				}
				this.open('add-project');
			}
		},
		// 列表菜单选择
		handleSelect(key, keyPath) {
			if (this.status == '') {
				this.$message({
					showClose: true,
					message: '请先选择企业或项目',
					type: 'warning'
				});
				return
			}
			this.activeIndex = keyPath[0];
			// // console.log(this.activeIndex)
			if (keyPath[0] == '1') {
				this.total = this.equipmentTotal;
			}
			// // console.log(key, keyPath);
		},
		// 列表模糊搜索
		fuzzyQuery() {
			this.$refs.equipment.getListData({
				page: 1,
				size: 30,
				name: this.searchCondition
			}, true);
		},
		// 选择企业/项目
		selectText(e, data, companyId = null, parentData = null) {
			this.clearData(this.enterprise_info);
			this.clearData(this.project_info);
			this.editMode = false;
			this.activeIndex = '1';
			this.currentPage = 1;
			this.status = '';
			if (companyId == null) {
				$(e.currentTarget).prev().eq(0).find('.caret').click();
			}
			this.status = $(e.currentTarget).attr('status');
			$('.text').removeClass('active');
			$(e.currentTarget).addClass('active');
			var text = this.status;
			this.enterprise_project = [];
			for (let key in data) {
				if (text == '企业') {
					let datas = data[key];
					// if (key == 'pn1' || key == 'pn2' || key == 'pn3') {
					// 	this.enterprise_info['img' + key[2]] = datas;
					// 	this.enterprise_backupInfo['img' + key[2]] = datas;
					// 	if (datas != '') {
					// 		datas = this.constData.imgUrl + "res/static/cmpp/" + datas;
					// 	}
					// }
					if (key != 'children') {
						this.enterprise_info[key] = datas;
						this.enterprise_backupInfo[key] = datas;
					} else {
						this.enterprise_project = datas;
					}
				} else if (text == '项目') {
					if (key != 'pn1') {
						this.project_info[key] = data[key];
						this.project_backupInfo[key] = data[key];
					} else {
						if (data[key] != '') {
							this.project_info[key] = this.constData.imgUrl + "res/static/ppname/1" + data[key];
							this.project_backupInfo[key] = this.constData.imgUrl + "res/static/ppname/1" + data[key];
						} else {
							this.project_info[key] = data[key];
							this.project_backupInfo[key] = data[key];
						}
					}
				}
			}
			if (text == '项目') {
				this.project_info.companyId = companyId;
				this.project_backupInfo.companyId = companyId;
				if (parentData != null) {
					this.project_parent_info = parentData;
				}
			}
			setTimeout(() => {
				let pageData = {
					name: this.searchCondition,
					page: this.currentPage,
					size: this.pagesize
				}
				this.getAllList(pageData);
			}, 200)
		},

		// 创建地图
		setMap(data, obj, val) {
			this.selectPoint = '';
			this.map = new BMap.Map(obj);
			if (obj == 'view-map') {
				var point = data.split(',');
				var points = null;
				this.open('map-pop-ups');
				setTimeout(() => {
					if (point.length == 2) {
						var lnglats = this.AMapToBMap(point[0], point[1]);
						this.map.centerAndZoom(lnglats, 8);
						this.map.enableScrollWheelZoom(true);
						points = new BMap.Point(Number(lnglats['lng']), Number(lnglats['lat']));
						this.setViewMap(points);
					} else {
						this.$message({
							showClose: true,
							message: '暂无当前位置信息！',
							type: 'error'
						});
					}
				}, 600)
			} else if (obj == 'pick-point') {
				setTimeout(() => {
					this.map.centerAndZoom(new BMap.Point(104.065735, 30.659462), 8);
					this.map.enableScrollWheelZoom(true);
				}, 200)
				this.open('pick-point');
				this.setPoint(val);
			}

		},
		// 地图标点
		setViewMap(data) {
			// // console.log(data)
			var allOverlay = this.map.getOverlays();
			for (var i = 0; i <= allOverlay.length - 1; i++) {
				if (allOverlay[i].hasOwnProperty("markId")) {
					this.map.removeOverlay(allOverlay[i]);
				}
			}
			var i = require("../../../assets/images/bicon.png");
			var myIcon = new BMap.Icon(i, new BMap.Size(40, 40), {
				imageSize: new BMap.Size(40, 40)
			});
			var marker1 = new BMap.Marker(data, {
				icon: myIcon,
				offset: new BMap.Size(0, -13)
			});
			marker1.markId = 'mark'
			this.map.addOverlay(marker1);
			// this.map.setViewport([data])
		},
		// 地图选点
		setPoint(val) {
			this.butName = val;

			this.map.addEventListener('click', (e) => {
				// console.log(e)
				this.selectPoint = '';
				var pointLng = e.point.lng;
				var pointLat = e.point.lat;
				this.setViewMap(e.point);
				var lnglats = this.BMapToAMap(pointLng, pointLat);; // Array.<LngLat>
				// // console.log(lnglats)
				var Alng = lnglats.lng.toString();
				var Alat = lnglats.lat.toString();
				var lng = Alng.slice(0, Alng.indexOf('.')) + Alng.slice(Alng.indexOf('.'), 10);
				var lat = Alat.slice(0, Alat.indexOf('.')) + Alat.slice(Alat.indexOf('.'), 10);
				this.selectPoint = lng + ',' + lat;
				if (val == '企业') {
					this.enterprise_info.location = lng + ',' + lat;
				} else if (val == '项目') {
					this.project_info.location = lng + ',' + lat;
				} else if (val == '新增企业') {
					this.addEnterprise.location = lng + ',' + lat;
				} else if (val == '新增项目') {
					this.addProject.location = lng + ',' + lat;
				}

			});
		},
		// 地图选点后确认将坐标赋值
		Assignment() {
			var val = this.butName;
			if (val == '企业') {
				this.enterprise_info.location = this.selectPoint;
			} else if (val == '项目') {
				this.project_info.location = this.selectPoint;
			} else if (val == '新增企业') {
				this.addEnterprise.location = this.selectPoint;
			} else if (val == '新增项目') {
				this.addProject.location = this.selectPoint;
			}
			this.close('pick-point');
		},
		// 地图输入地址名称查询
		inquirePoint() {
			this.localSearch = new BMap.LocalSearch(this.map, {
				renderOptions: {
					map: this.map
				},
			});
			// 获取当前视图中心点
			var center = new BMap.Point(this.map.getCenter().lng, this.map.getCenter().lat);
			var geoc = new BMap.Geocoder();
			geoc.getLocation(center, (rs) => {
				var addComp = rs.addressComponents;
				this.localSearch.searchNearby(this.inputName, addComp.city);
				// 查询完成结果返回函数
				this.localSearch.setSearchCompleteCallback((point) => {
					// // console.log(point)
				});
				this.localSearch.setMarkersSetCallback((point) => {
					// console.log(point);
					var allOverlay = this.map.getOverlays();
					for (var i = 0; i < allOverlay.length - 1; i++) {
						// console.log(allOverlay[i])
						allOverlay[i].addEventListener('click', (e) => {
							// // console.log(e)
							this.setViewMap(e.point);
							e.domEvent.stopPropagation();
						});
					}
				});
			});
		},
		// 打开弹窗 
		open(val, status, data = {}) {
			if (val != 'add-equipment') {
				$('.' + val).show();
			}

			if (val != 'pick-point' && val != 'map-pop-ups' && val != 'add-equipment' && val !=
				"projectSettings") {
				// if (this.judgment('authorityList', 56, this)) {
				this.getCompany();
				// }
			}
			if (val == 'add-equipment') {
				// 打开传感器新增设备弹窗
				this.$refs.equipment.open(val, status, data);
			} else if (val == 'projectSettings') {
				// // console.log(this.project_info)
				//  项目设置（测点布设图）
				this.$refs.pointImg.clearFile();
				this.$refs.imgSign.setUrl();
				// 项目描述
				this.describe.id = this.project_info.id;
				this.describe.content = this.project_info.content;
			} else if (val == 'pick-point') {
				this.inputName = '';
				this.selectPoint = '';
			}
		},
		// 关闭弹窗
		close(val) {
			$('.' + val).hide();
			if (val == 'add-enterprise') {
				this.editMode = false;
				//恢复新增企业数据
				this.clearData(this.addEnterprise);
			} else if (val == 'add-project') {
				this.editMode = false;
				//恢复新增项目数据
				this.clearData(this.addProject);
			} else if (val == 'add-equipment') {
				// 添加设备
			} else if (val == 'projectSettings') {
				// 项目设置（测点布设图）
				//恢复测点布设图测点数据
				this.clearData(this.addSettingMapPoint);
			}
		},
		// 清空数据赋值信息
		clearData(data) {
			// // console.log(data)
			for (let key in data) {
				if (key != 'id') {
					data[key] = '';
				} else {
					data[key] = null;
				}

			}
		},
		// 页码变化监听
		handleCurrentChange(val) {
			this.pagenumber = val;
			this.currentPage = val;
			let pageData = {
				name: this.searchCondition,
				page: this.currentPage,
				size: this.pagesize
			}
			if (this.activeIndex == '1') {
				this.$refs.equipment.getListData(pageData, true);
			}
		},
		// 跳转页码输入
		number() {
			if (this.pagenumber > Math.ceil(this.total / this.pagesize)) {
				this.pagenumber = Math.ceil(this.total / this.pagesize);
			} else if (this.pagenumber < 1 && this.pagenumber.toString() != '') {
				this.pagenumber = 1;
			}
		},
		// 跳转
		jump() {
			if (this.pagenumber == '') {
				this.pagenumber = 1;
			}
			this.currentPage = Number(this.pagenumber);
		},
		// 展开/折叠
		expandPanel(e) {
			var cla = $(e.currentTarget).attr('class');
			var child = $(e.currentTarget).parent().parent().next();
			if (cla.indexOf('expand') == -1) {
				child.show();
				$(e.currentTarget).addClass('expand');
				$(e.currentTarget).removeClass('animate-s');
				$(e.currentTarget).addClass('animate');
			} else {
				child.hide();
				$(e.currentTarget).removeClass('expand');
				$(e.currentTarget).addClass('animate-s');
			}
		}
	},
	components: {
		uploadImg,
		imgSign,
		equipment,
	},
	beforeDestroy() {},
	watch: {}
}
