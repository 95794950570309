<template>
	<div class="img-sign">
		<div class="box overflow" id="box" @dragover="allowDrop($event)" @dragstart="press($event)" @dragend="loosen($event)">
			<img @click="dianji($event)" id="img" :src="img" alt="图片获取失败" />
		</div>
		<!-- 全屏预览 -->
		<div class="preview" title="全屏预览" v-if="srcList.length != 0"><el-image style="width: 20px; height: 20px" :src="url" :preview-src-list="srcList"></el-image></div>
	</div>
</template>

<script>
export default {
	props:{
		// 图片地址
		imgUrl:{
			type:String,
			default: "",
		}
	},
	data() {
		return {
			obj: null,
			scrollTop: 0,
			scrollLeft: 0,
			startX: 0,
			startY: 0,
			startLeft: 0,
			startTop: 0,
			arr: [],
			img: '',//布设图图片地址
			url: require('../../../../assets/images/fullScreen.png'),
			srcList: [] //预览大图图片地址
		};
	},
	created() {
		// if(this.imgUrl != ''){
		// 	this.setUrl();
		// }
	},
	mounted() {
		var obj = (this.obj = $('#box'));
		window.stop = this.stop;
	},
	methods: {
		// 设置图片地址
		setUrl(){
			// // console.log(this.imgUrl)
			this.img = this.imgUrl;
			this.srcList = [this.img];
		},
		allowDrop(e) {
			e.preventDefault();
			// this.startTop > e.screenY 滚动条下移
			// Math.abs(this.startLeft) < Math.abs(e.screenX) 滚动条右移
			var top = 0;
			var left = 0;
			if (this.startTop > e.screenY) {
				top = this.scrollTop + Math.abs(this.startY - e.layerY);
			}
			if (this.startTop < e.screenY) {
				top = this.scrollTop - Math.abs(this.startY - e.layerY);
			}

			if (Math.abs(this.startLeft) < Math.abs(e.screenX)) {
				left = this.scrollLeft - Math.abs(this.startX - e.layerX);
			}

			if (Math.abs(this.startLeft) > Math.abs(e.screenX)) {
				left = this.scrollLeft + Math.abs(this.startX - e.layerX);
			}

			if (top < 0) {
				top = 0;
			}
			if (left < 0) {
				left = 0;
			}

			this.obj.scrollTop(top);
			this.obj.scrollLeft(left);
		},
		loosen(e) {},
		press(e) {
			this.startX = e.layerX;
			this.startY = e.layerY;
			this.startLeft = e.screenX;
			this.startTop = e.screenY;
			this.scrollLeft = this.obj.scrollLeft();
			this.scrollTop = this.obj.scrollTop();
		},
		dianji(e) {
			return
			$('.tagicon').remove();
			$('.content').remove();

			var img = this.img;
			var imgWidth = $('#img').width();
			var imgHeight = $('#img').height();
			var time = new Date().getTime();
			this.arr = [];
			this.arr.push({
				left: e.offsetX - 2,
				top: e.offsetY - 31,
				time: time
			});
			var contentX = e.offsetX - 100;
			var contentY = e.offsetY - 230;
			var css = `position: absolute;top:${e.offsetY - 31}px;left:${e.offsetX - 2}px;`;
			var css1 = '';
			if (contentX < 0) {
				if (contentY < 0) {
					css1 = `position: absolute;top:${e.offsetY}px;left:${e.offsetX}px;`;
				} else {
					css1 = `position: absolute;top:${contentY}px;left:${e.offsetX}px;`;
				}
			} else if (contentX > 0 && imgWidth - e.offsetX > 100) {
				if (contentY < 0) {
					css1 = `position: absolute;top:${e.offsetY}px;left:${contentX}px;`;
				} else {
					css1 = `position: absolute;top:${contentY}px;left:${contentX}px;`;
				}
			} else if (imgWidth - e.offsetX < 100) {
				if (contentY < 0) {
					css1 = `position: absolute;top:${e.offsetY}px;left:${e.offsetX - 220}px;`;
				} else {
					css1 = `position: absolute;top:${contentY}px;left:${e.offsetX - 220}px;`;
				}
			}

			var htmltxt = `
			<div class="content" style="${css1}">
				<p class="title">
					ZD-001
				</p>
				<p>2021/03/24</p>
				<p>X:0.1234cm/s</p>
				<p>Y:0.1234cm/s</p>
				<p>Z:0.1234cm/s</p>
				<p>报警未处理：1次 实时监控</p>
			</div>
			<img class=${'tagicon tag' + time}  src="${img}" style="${css}" onclick="stop(event)" />
			`;
			$('#box').append(htmltxt);
		},
		stop(e) {
			e.stopImmediatePropagation();
		}
	}
};
</script>

<style lang="scss">
// 布设图标记点样式
.img-sign {
	.content {
		width: 200px;
		height: 160px;
		border: 3px solid #000000;
		padding: 10px;
		text-align: left;

		.title {
			font-size: 17px !important;
		}

		p {
			padding: 5px;
			margin: 0;
			font-size: 13px;
			font-weight: 600;
		}
	}
}
</style>

<style lang="scss" scoped="scoped">
.img-sign {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	border-left: thin solid #dcdfe6;
	position: relative;

	.box {
		width: calc(100% + 17px);
		height: calc(100% + 17px);
		position: relative;
		text-align: center;
		font-size: 17px;
		color: #000;
	}
	.preview {
		width: 30px;
		height: 30px;
		border-radius: 10px;
		background-color: rgba($color: #606266, $alpha: 0.6);
		position: absolute;
		bottom: 18px;
		left: 2px;
		right: 0;
		// margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.overflow {
		overflow-y: scroll;
		overflow-x: scroll;
	}
}
</style>
