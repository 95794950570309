<template>
	<!-- 变形设备管理 -->
	<div class="equipmentManagement">
		<div class="equipmentManagement-main">
			<!-- 选择框盒子 -->
			<div class="left-box">
				<div class="top">
					<div class="search-button">
						<p class="title-text margin-bottom">企业项目</p>
						<el-input placeholder="请输入公司名称" autocomplete="off" v-model="searchContent">
							<el-button slot="append" size="small" icon="el-icon-search" @click="search"></el-button>
						</el-input>
						<div class="but-box">
							<el-button type="primary" size="mini" title="添加企业" @click="open('add-enterprise')" v-show="judgment('authorityList', 289, this)">
								<span class="icon iconfont icon-tianjiaqiyekehu"></span>
							</el-button>
							<el-button type="primary" size="mini" title="添加项目" @click="open('add-project')" v-show="judgment('authorityList', 290, this)">
								<span class="icon iconfont icon-tianjiaxiangmu"></span>
							</el-button>
						</div>
					</div>
				</div>
				<div class="scroll-list-box">
					<vue-scroll>
						<div class="scroll-box">
							<ul class="parent" v-for="(i, index) in listData" :key="index">
								<li class="title-li pointer">
									<p class="icon-box"><span v-show="i.children.length != 0" @click="expandPanel" class="el-icon-caret-right caret icon"></span></p>
									<span class="text text-overflow text-box" :status="i.hasOwnProperty('pn') ? '企业' : '项目'" @click.stop="selectText($event, i)">
										<i class="number">{{ index + 1 }}</i>
										<span class="parent-name" :title="i.name">{{ i.name }}</span>
									</span>
								</li>
								<li class="child">
									<ul>
										<li
											class="text pointer text-overflow content-li"
											status="项目"
											v-for="(item, ind) in i.children"
											:key="ind"
											@click.stop="selectText($event, item, i.id, i)"
										>
											<i class="number">{{ ind + 1 }}</i>
											<span class="child-name" :title="item.name">{{ item.name }}</span>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</vue-scroll>
				</div>
			</div>
			<!-- 列表盒子 -->
			<div class="right-box" v-if="status != ''">
				<!-- 头部信息显示 -->
				<div class="info-box">
					<div class="top">
						<p class="title">{{ (status == '企业' ? enterprise_info.name : project_info.name) | dataDetect }}</p>
					</div>
					<div class="enterprise" v-show="status == '企业'">
						<!-- <vue-scroll :ops="ops"> -->
						<div class="info-content">
							<div class="content-box">
								<div class="cell width-s">
									<span class="name">负责人:</span>
									<span class="content text-overflow" :title="enterprise_info.person | dataDetect">{{ enterprise_info.person | dataDetect }}</span>
								</div>
								<div class="cell width-l">
									<span class="name">地址:</span>
									<span class="icon iconfont icon-ziyuan pointer address-i" @click="setMap(enterprise_info.location, 'view-map', '企业')"></span>
									<span class="content-s text-overflow" :title="enterprise_info.address | dataDetect">{{ enterprise_info.address | dataDetect }}</span>
								</div>
							</div>
							<div class="button-box">
								<el-button v-show="judgment('authorityList', 291, this)" plain type="primary" icon="el-icon-edit" size="mini" @click="infoEdit('企业')"></el-button>
								<el-button
									v-show="judgment('authorityList', 292, this)"
									type="danger"
									plain
									icon="el-icon-delete"
									size="mini"
									@click="enterpriseDelete(enterprise_info.id, enterprise_info.name)"
								></el-button>
								<!-- 	<el-button v-show="editMode" size="small" type="primary" @click="modifyEnterprise">确定</el-button>
									<el-button v-show="editMode" size="small" type="info" @click="cancelModify('企业')">取消</el-button> -->
							</div>
						</div>
						<!-- </vue-scroll> -->
					</div>
					<div class="project" v-show="status == '项目'">
						<vue-scroll :ops="ops">
							<div class="info-content">
								<div class="content-box">
									<div class="cell width-s">
										<span class="name">负责人:</span>
										<span class="content text-overflow" :title="project_info.person | dataDetect">{{ project_info.person | dataDetect }}</span>
									</div>
									<div class="cell width-l">
										<span class="name">地址:</span>
										<span class="icon iconfont icon-ziyuan pointer address-i" @click="setMap(project_info.location, 'view-map', '项目')"></span>
										<span class="content-s text-overflow" :title="project_info.address | dataDetect">{{ project_info.address | dataDetect }}</span>
									</div>
									<div class="cell width-l">
										<span class="name">爆破单位:</span>
										<span class="content-ss text-overflow" :title="project_info.bcname | dataDetect">{{ project_info.bcname | dataDetect }}</span>
									</div>
									<!-- <div class="cell width-xs scenario" v-show="editMode">
										<span class="name">场景</span>
										<el-select popper-class="short-select" v-model="project_info.en" placeholder="请选择场景">
										    <el-option
										      v-for="item in scenario"
										      :key="item.value"
										      :label="item.label"
										      :value="item.label"
											  >
										    </el-option>
										  </el-select>
									</div> -->
								</div>
								<div class="button-box">
									<el-button v-show="judgment('authorityList', 295, this)" plain type="primary" size="mini" @click="open('projectSettings')">项目设置</el-button>
									<el-button v-show="judgment('authorityList', 296, this)" plain type="primary" size="mini" @click="endProject">结束项目</el-button>
									<el-button
										v-show="judgment('authorityList', 293, this)"
										plain
										type="primary"
										icon="el-icon-edit"
										size="mini"
										@click="infoEdit('项目')"
									></el-button>
									<el-button
										v-show="judgment('authorityList', 294, this)"
										type="danger"
										plain
										icon="el-icon-delete"
										size="mini"
										@click="projectDelete(project_info.id, project_info.name)"
									></el-button>
									<!-- <el-button v-show="editMode" size="small" type="primary" @click="modifyProject">确定</el-button>
									<el-button v-show="editMode" size="small" type="info" @click="cancelModify('项目')">取消</el-button> -->
								</div>
							</div>
						</vue-scroll>
					</div>
				</div>
				<div class="menu-list-box">
					<!-- 列表菜单 -->
					<div class="menu-box">
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
							<el-menu-item index="1">设备列表</el-menu-item>
						</el-menu>
						<div class="search-button">
							<el-input placeholder="请输入设备编号" autocomplete="off" v-model="searchCondition">
								<el-button slot="append" size="small" icon="el-icon-search" @click="fuzzyQuery"></el-button>
							</el-input>
							<el-button
								class="but"
								type="primary"
								plain
								size="small"
								v-show="activeIndex == '1'"
								@click="open('add-equipment', false)"
							>
								添加设备
							</el-button>
						</div>
					</div>

					<!-- 数据展示 -->
					<div class="data-box">
						<!-- 设备列表 -->
						<equipment ref="equipment" :authorityList="authorityList" :enterprise_project="enterprise_project" :text="status" v-show="activeIndex == 1"></equipment>
					</div>

					<!-- 底部分页 -->
					<div class="bottombox">
						<el-pagination
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage"
							:page-size="pagesize"
							:pager-count="5"
							background
							layout="prev, pager, next, total, slot"
							:total="total"
						>
							<div style="display: inline-block;">
								<div class="slotbox">
									<span>到第</span>
									<input type="number" @input="number()" v-model="pagenumber" class="pageinput" />
									<span>页</span>
									<button @click="jump">确认</button>
								</div>
							</div>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 新增企业 -->
		<div class="add-enterprise Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editMode ? '修改企业' : '新增企业' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-enterprise')"></span>
				</p>`
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							企业名称
						</span>
						<p class="cell-content" v-show="!judgment('authorityList', 274, this)">{{ addEnterprise.name }}</p>
						<el-input v-if="judgment('authorityList', 274, this)" v-model="addEnterprise.name" placeholder="请输入内容"></el-input>
					</div>
					<div class="cell">
						<span class="title">坐标</span>
						<el-input class="coordinate" v-model="addEnterprise.location" placeholder="请输入内容">
							<el-button slot="append" size="mini" @click="setMap('', 'pick-point', '新增企业')"><span class="icon iconfont icon-ziyuan"></span></el-button>
						</el-input>
					</div>
					<div class="cell">
						<span class="title">地址</span>
						<el-input v-model="addEnterprise.address" placeholder="请输入内容"></el-input>
					</div>
					<div class="button-box">
						<el-button v-show="!editMode" :disabled="addEnterprise.name == ''" type="primary" size="mini" @click="addCompany">添加</el-button>
						<el-button v-show="editMode" size="small" type="primary" @click="modifyEnterprise">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 新增项目 -->
		<div class="add-project Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editMode ? '修改项目' : '新增项目' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-project')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							项目名称
						</span>
						<el-input v-model="addProject.name" placeholder="请输入内容"></el-input>
					</div>
					<!-- v-show="judgment('authorityList', 207, this)" -->
					<div class="cell">
						<span class="title">
							<i>*</i>
							所属企业
						</span>
						<el-select :disabled="editMode" v-model="addProject.companyId" filterable placeholder="请选择企业">
							<el-option v-for="item in allEnterprise" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<span class="title">爆破单位</span>
						<el-input v-model="addProject.bcname" placeholder="请填写爆破单位"></el-input>
					</div>
					<div class="cell">
						<span class="title">坐标</span>
						<el-input class="coordinate" v-model="addProject.location" placeholder="请输入内容">
							<el-button slot="append" size="mini" @click="setMap('', 'pick-point', '新增项目')"><span class="icon iconfont icon-ziyuan"></span></el-button>
						</el-input>
					</div>
					<div class="cell">
						<span class="title">地址</span>
						<el-input v-model="addProject.address" placeholder="请输入内容"></el-input>
					</div>
					<div class="cell" v-show="!editMode">
						<span class="title">描述</span>
						<el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="addProject.content"></el-input>
					</div>
					<div class="button-box">
						<el-button v-show="!editMode" :disabled="addProject.name == '' || addProject.companyId == ''" type="primary" size="mini" @click="addProjectf">
							添加
						</el-button>
						<el-button v-show="editMode" size="small" type="primary" @click="modifyProject">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改 -->
		<!-- 项目设置 添加/编辑测点布设图和项目描述 -->
		<div class="projectSettings point-style Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ settingMap ? '项目设置' : '项目设置' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('projectSettings')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="top-box">
						<p class="title">项目描述</p>
						<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="describe.content"></el-input>
					</div>
					<div class="bottom-box">
						<div class="top-but">
							<span class="title">测点布设图</span>
							<el-button v-show="project_info.pn1 != ''" title="删除测点布设图" type="danger" icon="el-icon-delete" size="mini" @click="deleteProjectImg"></el-button>
						</div>
						<div class="upload-box" v-show="project_info.pn1 == ''">
							<p class="pTitle">点击上传图片</p>
							<uploadImg ref="pointImg" :configuration="parameterPoint" />
						</div>
						<img-sign ref="imgSign" v-show="project_info.pn1 != ''" :imgUrl="project_info.pn1"></img-sign>
					</div>
					<div class="bottom-but"><el-button type="primary" size="small" @click="projectSettings">确定</el-button></div>
				</div>
			</div>
		</div>
		<!-- 地图展示弹窗 -->
		<div class="map-pop-ups Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>地址</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('map-pop-ups')"></span>
				</p>
				<div class="pop-ups-content"><div id="view-map"></div></div>
			</div>
		</div>
		<!-- 地图选点 -->
		<div class="pick-point Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>经纬度</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('pick-point')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="input left">
						<el-input v-model="inputName" placeholder="可输入查询城市名"></el-input>
						<el-button type="primary" size="small" :disabled="inputName == ''" @click="inquirePoint">确定</el-button>
					</div>
					<div class="input right">
						经纬度：
						<el-input v-model="selectPoint" readonly placeholder="当前选点经纬度"></el-input>
						<el-button type="primary" size="small" @click="Assignment">确定</el-button>
					</div>
					<div id="pick-point"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import equipmentManagement from './equipmentManagement.js';
export default equipmentManagement;
</script>

<style scoped lang="scss">
@import './equipmentManagement.scss';
</style>
